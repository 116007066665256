import React, { useState, createRef, useEffect } from "react";
import { Container, Row, Col, Form, Button, ProgressBar } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2'
import config from "../../config"


import ReactLoading from 'react-loading';
import { useNetworkState } from 'react-use';


const Left_menu = (props) => {
    let navigate = useNavigate()
    const isOnline = useNetworkState();
    console.log("isONline  === " ,isOnline )
  let userDetail = localStorage.getItem('ship_rocket_user');
  // console.log("userDetail config====", config.UserId);
  const SUPPORT = config.SUPPORT
  const [state, setState] = useState({ email: "",password:"" });
  const [regsterState, setRegsterState] = useState({ email: "",password:"",full_name:"" });
  const [modalState, setModalState] = useState({ show: false });
  const [typeState, setTypeState] = useState({ type: "login" });
  const [userState, setUserState] = useState({ full_name: "" });
  const [styleState, setStyleState] = useState({ styles: 0 });
  const [trackState, setTrackState] = useState({tracking_no:""})
  const[trackresult,setTrackresult] = useState({})
  const[errorState,setErrorState] = useState()
  const[isLoading,setisLoading] = useState(false)
  const[modals,setModals] = React.useState({show: false})
  const[arrow,setarrow] = React.useState(0)

  const [servicestate, setserviceState] = React.useState({
    web_body_list: [],
  isLoading:true  
  })
  
  let userData = {};
  if (userDetail != null) {
    userData = JSON.parse(userDetail)
  }
  const Toast = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 3000
  })

    const logOutButton = () =>{
        // console.log("userDetail ===>   ");
      
        let full_api = config.apiUrl + `/user/user_logout`;
          let sendData = {customer_id:userData.customer_id};
          // console.log("bb", sendData);
          axios.post(full_api, sendData, { headers: config.headers }).then((res) => {
              // console.log("res ", res);
              if (res.data.status==true) {
                localStorage.removeItem('ship_rocket_user');
                userDetail = null;
                setUserState({full_name:""})
                navigate('/customer/login')
              }else {
                Toast.fire({
                  background:"rgb(231, 91, 75)",
                  type: 'unsuccess',
                  title: res.data.message ,
                  color:"white"
                });
                 
              }
      
          }).catch((e) => {
      
      
          });
        
      
      }
    let value  = props.value
    // console.log("value ====" , value)

    const arrowset =()=>{
        if(arrow==0){
            setarrow(1)
        }
        else{
            setarrow(0)
        }
    }
  return (
    // <div class={arrow==0?"sidebar":"sidebar close"}>
    //   <nav >
    //     <header>
    //         <div class="image-text">
    //             <span class="image">
    //                 <img src="../../img/logo.png" alt=""/>
    //             </span>

    //             <div class="text logo-text">
    //                 <span class="name">Shiport.in</span>
    //                 <span class="profession">{userData.full_name}</span>
    //             </div>
    //         </div>

    //         <i class='bx bx-chevron-right toggle' onClick={(e)=>arrowset(e)}></i>
    //     </header>

    //     <div class="menu-bar">
    //         <div class="menu">

    //             {/* <!-- <li class="search-box">
    //                 <i class='bx bx-search icon'></i>
    //                 <input type="text" placeholder="Search...">
    //             </li> --> */}

    //             <ul class="menu-links">
    //                 <li class="nav-link">
    //                     <a href="/" class={value==1?"active":""}>
    //                         <i class='bx bx-home-alt icon' ></i>
    //                         <span class="text nav-text">Dashboard</span>
    //                     </a>
    //                 </li>

    //                 <li class="nav-link">
    //                     <a href="/customer/orders" class={value==2?"active":""}>
    //                         <i class='bx bx-cart icon' ></i>
    //                         <span class="text nav-text">Orders</span>
    //                     </a>
    //                 </li>

    //                 <li class="nav-link">
    //                     <a href="/customer/shipments" class={value==3?"active":""}>
    //                         <i class='bx bx-package icon'></i>
    //                         <span class="text nav-text">Shipments</span>
    //                     </a>
    //                 </li>

    //                 {/* <li class="nav-link">
    //                     <a href="coming_soon.php">
    //                         <i class='bx bx-task-x icon' ></i>
    //                         <span class="text nav-text">NDR</span>
    //                     </a>
    //                 </li>

    //                 <li class="nav-link">
    //                     <a href="coming_soon.php">
    //                         <i class='bx bx-reset icon' ></i>
    //                         <span class="text nav-text">Returns</span>
    //                     </a>
    //                 </li> */}

    //                 <li class="nav-link">
    //                     <a href="/customer/warehouse" class={value==6?"active":""}>
    //                         <i class='bx bx-building-house icon' ></i>
    //                         <span class="text nav-text">Warehouse</span>
    //                     </a>
    //                 </li>


    //                 <li class="nav-link">
    //                     <a href="/customer/billing_shipping_charges" className={value==7 || value==8 || value ==9?"active":""}>
    //                         <i class='bx bx-file icon' ></i>
    //                         <span class="text nav-text">Billings</span>
    //                     </a>
    //                 </li>

    //                 <li class="nav-link">
    //                     <a href="/customer/tools" className={value ==10?"active":""}>
    //                         <i class='bx bx-candles icon' ></i>
    //                         <span class="text nav-text">Tools</span>
    //                     </a>
    //                 </li>

    //                 <li class="nav-link">
    //                     <a href="/customer/settings" className={value ==11?"active":""}>
    //                         <i class='bx bx-cog icon' ></i>
    //                         <span class="text nav-text">Settings</span>
    //                     </a>
    //                 </li>
    //                 {SUPPORT?
    //                 <li class="nav-link">
    //                     <a href="/customer/tickets" className={value ==12?"active":""}>
    //                         <i class='bx bx-cog icon' ></i>
    //                         <span class="text nav-text">Support</span>
    //                     </a>
    //                 </li>
    //                 :""}
    //                    <li class="nav-link">
    //                     <a href="/customer/reports" className={value ==13?"active":""}>
    //                         <i class='bx bx-cog icon' ></i>
    //                         <span class="text nav-text">Reports</span>
    //                     </a>
    //                 </li>
    //                 {/* <li class="nav-link">
    //                     <a href="/customer/invoices" className={value ==14?"active":""}>
    //                         <i class='bx bx-cog icon' ></i>
    //                         <span class="text nav-text">Invoices</span>
    //                     </a>
    //                 </li> */}

    //             </ul>
    //         </div>

    //         <div class="bottom-content">
    //             <li class="">
    //                 <a onClick={() => logOutButton()}>
    //                     <i class='bx bx-log-out icon' ></i>
    //                     <span class="text nav-text">Logout</span>
    //                 </a>
    //             </li>

    //             {/* <!-- <li class="mode">
    //                 <div class="sun-moon">
    //                     <i class='bx bx-moon icon moon'></i>
    //                     <i class='bx bx-sun icon sun'></i>
    //                 </div>
    //                 <span class="mode-text text">Dark mode</span>

    //                 <div class="toggle-switch">
    //                     <span class="switch"></span>
    //                 </div>
    //             </li> -->
    //              */}
    //         </div>
    //     </div>

    // </nav>
    // </div>
    <div class={arrow==0?"sidebar close":"sidebar "}>
    <nav class="">
        <header>
            <div class="image-text">
                <span class="image">
                    <img src="../../../img/logo_5.png" alt=""/>
                </span>

                <div class="text logo-text">
                    <span class="name">Shiport.in</span>
                    <span class="profession">{userData.full_name}</span>
                </div>
            </div>

            <i class='bx bx-chevron-right toggle' onClick={(e)=>arrowset(e)}></i>
        </header>

        <div class="menu-bar">
            <div class="menu">

                {/* <!-- <li class="search-box">
                    <i class='bx bx-search icon'></i>
                    <input type="text" placeholder="Search...">
                </li> --> */}

<ul class="menu-links">
                     <li class="nav-link">
                         <a href="/" class={value==1?"active":""}>
                             <i class='bx bx-home-alt icon' ></i>
                             <span class="text nav-text">Dashboard</span>
                         </a>
                     </li>

                     <li class="nav-link">
                         <a href="/customer/orders" class={value==2?"active":""}>
                             <i class='bx bx-cart icon' ></i>
                             <span class="text nav-text">B2C Orders</span>
                         </a>
                     </li>

                     {/* <li class="nav-link">
                         <a href="/customer/b2b_orders" class={value==20?"active":""}>
                             <i class='bx bx-cart icon' ></i>
                             <span class="text nav-text">B2B Orders</span>
                         </a>
                     </li> */}

                     <li class="nav-link">
                         <a href="/customer/shipments" class={value==3?"active":""}>
                             <i class='bx bx-package icon'></i>
                             <span class="text nav-text">Shipments</span>
                         </a>
                    </li>

                    <li class="nav-link">
                    <a href="/customer/weight_mismatch" class={value==21?"active":""}  data-bs-toggle="tooltip" data-bs-placement="right" title="Weight Mismatch">
                            <i class='bx bx-scatter-chart icon' ></i>
                            <span class="text nav-text">Weight Mismatch</span>
                        </a>
                     </li>

                     {/* <li class="nav-link">
                         <a href="coming_soon.php">
                             <i class='bx bx-reset icon' ></i>
                            <span class="text nav-text">Returns</span>
                         </a>
                     </li> */}

                     <li class="nav-link">
                         <a href="/customer/warehouse" class={value==6?"active":""}>
                             <i class='bx bx-building-house icon' ></i>
                             <span class="text nav-text">Warehouse</span>
                         </a>
                     </li>


                     <li class="nav-link">
                         <a href="/customer/billing_shipping_charges" className={value==7 || value==8 || value ==9?"active":""}>
                             <i class='bx bx-file icon' ></i>
                             <span class="text nav-text">Billings</span>
                         </a>
                     </li>

                     <li class="nav-link">
                         <a href="/customer/tools" className={value ==10?"active":""}>
                             <i class='bx bx-candles icon' ></i>
                             <span class="text nav-text">Tools</span>
                         </a>
                     </li>

                     <li class="nav-link">
                         <a href="/customer/settings" className={value ==11?"active":""}>
                             <i class='bx bx-cog icon' ></i>
                             <span class="text nav-text">Settings</span>
                         </a>
                     </li>
                     {SUPPORT?
                     <li class="nav-link">
                         <a href="/customer/tickets" className={value ==12?"active":""}>
                             <i class='bx bx-support icon' ></i>
                             <span class="text nav-text">Support</span>
                         </a>
                     </li>
                     :""}
                        <li class="nav-link">
                         <a href="/customer/reports" className={value ==13?"active":""}>
                             <i class='bx bx-receipt icon' ></i>
                             <span class="text nav-text">Reports</span>
                         </a>
                     </li>
                     {/* <li class="nav-link">
                         <a href="/customer/invoices" className={value ==14?"active":""}>
                             <i class='bx bx-cog icon' ></i>
                             <span class="text nav-text">Invoices</span>
                         </a>
                     </li> */}

                </ul>
            </div>

            <div class="bottom-content">
                <li class="">
                    <a onClick={() => logOutButton()}  data-bs-toggle="tooltip" data-bs-placement="right" title="Logout">
                        <i class='bx bx-log-out icon' ></i>
                        <span class="text nav-text">Logout</span>
                    </a>
                </li>

                {/* <!-- <li class="mode">
                    <div class="sun-moon">
                        <i class='bx bx-moon icon moon'></i>
                        <i class='bx bx-sun icon sun'></i>
                    </div>
                    <span class="mode-text text">Dark mode</span>

                    <div class="toggle-switch">
                        <span class="switch"></span>
                    </div>
                </li> --> */}
                
            </div>
        </div>

    </nav>
    </div>
  )
}

export default Left_menu
